import * as React from 'react';
import {useState} from 'react';
import CategoryLayout from '../components/CategoryLayout';
import Featured from '../components/layout/Featured';
import FeaturedFlash from '../components/layout/FeaturedFlash';
import OtherPosts from '../components/layout/OtherPosts';
import RecentPosts from '../components/layout/RecentPosts';
import FlashCards from '../components/FlashCards';
import LongCards from '../components/LongCards';
import Table from '../components/Table';
import {
  usePulsoTecnicoPosts,
  useFlashTecnicoPosts,
  useCarteraTecnicoPosts,
  // useHomePosts,
  useOtrosArticulosPosts,
  useTablaDeRecomendacionesTecnico,
} from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';
import { ta } from 'date-fns/locale';
import useIsClient from '../utils/isClient';

import "../theme/category.scss"
import SearchIcon from '@material-ui/icons/Search';
import HeaderLayout from '../components/layout/HeaderLayout';
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';

import ModalSuscribe from '../components/layout/ModalSuscribre';


export default function Tecnico(): JSX.Element {
  const pulsoTecnicoPosts = usePulsoTecnicoPosts();
  const flashTecnicoPosts = useFlashTecnicoPosts();
  const carteraTecnicoPosts = useCarteraTecnicoPosts();
  const otherPosts = useOtrosArticulosPosts();
  const tablaDeRecomendaciones = useTablaDeRecomendacionesTecnico();
  const color = getCategoryColor('tecnico');

  // console.log(pulsoTecnicoPosts)
  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }
  const isClient = useIsClient();
  if(isClient){
    return (
      <>
        <HeaderLayout mb={true} openModal={openSuscribe} />
        <div className="container">
          <div className='header--section'>
            <h2 className='title--section'>Técnico</h2>
            <div className='search--section'>
              <form action="/buscar">
                <input type="text" name='q' />
                <SearchIcon className='icon--search'/>  
              </form>
            </div>
          </div>

          <Featured data={pulsoTecnicoPosts[0]}/>
          <FeaturedFlash post={flashTecnicoPosts[0]}/>
          <RecentPosts posts={pulsoTecnicoPosts} title="Artículos recientes"/>
          <FlashCards header="Flash Técnico" posts={flashTecnicoPosts} />
          <OtherPosts posts={otherPosts}/>

        </div>
        <CustomFooter openModal={openSuscribe} />
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      {/*
      <CategoryLayout title="Técnico" newHeader={true}>
        <Featured header="Diario Técnico" post={pulsoTecnicoPosts[0]} />
        <FeaturedFlash
          header="Flash Destacado"
          post={flashTecnicoPosts[0]}
          barColor={color}
        />
        <RecentPosts header="Artículos Recientes" posts={pulsoTecnicoPosts} />
        <FlashCards header="Flash Técnico" posts={flashTecnicoPosts} />
        <OtherPosts header="Otros Artículos" posts={otherPosts} />
        */}
      
        {/* <LongCards header="Cartera Técnico" posts={carteraTecnicoPosts} /> */}
        {/* <Table
          header={tablaDeRecomendaciones.tabla.titulo}
          src={tablaDeRecomendaciones.tabla.tabla}
        /> */}

      {/* </CategoryLayout> */}
      </>
    );
  }else{
    return(<></>)
  }
}
